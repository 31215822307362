
import { Options, Vue } from 'vue-class-component'
import NaviBar from '@/components/NaviBar.vue'
import service from '@/utils/request'
import { Toast } from 'vant'
import { Practice } from '@/models/practice'

@Options({
  components: {
    NaviBar
  }
})
export default class LifePractice extends Vue {
  text = ''
  fileList = []
  checked = false
  taskId = ''
  practiceId = ''
  isDaily = '0'
  isPopShow = false
  isInfoShow = false
  practice: Practice = {
    collectionId: '',
    content: [],
    cover: '',
    id: 0,
    introduction: '',
    isCollected: false,
    name: '',
    resourceType: '',
    type: '',
    isDailyPractice: false
  }

  mounted () {
    this.practiceId = this.$route.params.practiceId as string
    this.taskId = this.$route.params.taskId as string

    this.loadData()
  }

  loadData () {
    service.get('/mindup/mini/practice/' + this.practiceId, {}).then(res => {
      this.practice = res.data
    })
  }

  afterRead (file: any) {
    console.log(file)
    Toast.loading({
      message: '上传图片',
      duration: 30000,
      mask: true
    })
    service.post('/files/upload/base64-image', { imageValue: file.content }).then(res => {
      console.log(res.data)
      file.url = res.data.url
      Toast.loading({
        message: '上传完成',
        duration: 1000
      })
    })
  }

  publish () {
    if (this.text.length < 5) {
      Toast.fail({
        message: '请输入内容不得少于5个字',
        duration: 2000
      })
      return
    }

    const picArr: any[] = []
    this.fileList.forEach((item: any) => {
      console.log('item == ', item)
      const pic = { pic: item.url }
      picArr.push(pic)
    })
    Toast.loading({
      message: '上传中',
      duration: 100000
    })

    service.post('/mindup/mini/tasks/' + this.taskId + '/task-note/submit', {
      content: this.text,
      isSendTeacher: this.checked,
      pics: picArr
    }).then(res => {
      this.complete()
    })
  }

  complete () {
    service.post('/mindup/mini/tasks/' + this.taskId + '/practice-task/complete', {
      exerciseDuration: 0,
      isStandard: true
    }).then(res => {
      Toast.success({
        message: '练习完成',
        duration: 2000
      })
      setTimeout(() => {
        this.$router.push({ name: 'Practice' })
      }, 2000)
    })
  }

  tapSetting () {
    this.isPopShow = true
  }

  tapInfo () {
    this.isPopShow = false
    this.isInfoShow = true
  }

  tapDaily () {
    service.post('/mindup/mini/practice/' + this.practiceId + '/daily-practice/add-or-cancel', {}).then(res => {
      this.practice.isDailyPractice = res.data.isDailyPractice
    })
  }
}
